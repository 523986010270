@use 'media';

.root {
  flex: 1 1 0;
  margin-right: 64px;

  .icon {
    position: relative;
    top: 2px;
    margin-right: 8px;
  }

  @include media.down('tablet') {
    margin-right: 0;
    margin-bottom: 64px;
    text-align: center;
  }

  &:last-of-type {
    margin-right: 0;
    margin-bottom: 0;
  }
}
