@use 'colors';
@use 'layout';
@use 'shadows';
@use 'media';

.root {
  cursor: pointer;
  color: colors.$white;

  .title {
    min-height: 70px;
    padding: 0 24px;
    @include layout.center-flex;
    flex-direction: row;

    > svg {
      @include media.down('tablet') {
        display: none;
      }
    }
  }

  .details {
    display: none;
  }

  @include media.down('tablet') {
    margin-bottom: 16px;
  }

  &.selected {
    background-color: colors.$white;
    box-shadow: shadows.$paper;

    border: 1px solid rgba(151, 151, 151, 0.08);
    border-radius: 4px;

    color: colors.$blue;

    .details {
      display: block;

      @include media.up('tablet') {
        display: none;
      }
    }
  }
}
