@use 'gradients';
@use 'colors';
@use 'layout';
@use 'media';

.list {
  list-style-type: disc;
  list-style-position: inside;
}

.bullet {
  padding-bottom: 8px;
}

.titleDesktop {
  @include media.down('tablet') {
    display: none;
  }
}

.titleMobileTablet {
  @include media.up('tablet') {
    display: none;
  }
}

.root {
  background: gradients.$swoops, gradients.$green-to-blue;
  color: colors.$white;
  padding: 22px;

  @include media.up('mobile') {
    padding: 30px 0 0 30px;
    border-bottom-right-radius: 20px;
  }

  @include media.up('tablet') {
    padding: 64px 0 0 64px;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    @include media.down('mobile') {
      flex-direction: column-reverse;
    }

    .information {
      @include media.up('mobile') {
        padding-bottom: 30px;
        padding-right: 30px;
        min-width: 100px;
      }

      @include media.up('tablet') {
        padding-right: 64px;
      }
    }

    .image {
      @include media.up('mobile') {
        align-self: flex-end;
        margin-bottom: -1px;
      }

      @include media.down('mobile') {
        padding-bottom: 22px;
        width: auto;
      }

      max-width: 100%;
      width: 50%;
    }
  }
}
