@use 'colors';
@use 'layout';
@use 'shadows';
@use 'media';

$bar-height: 72px;

.root {
  background-color: colors.$white;
  padding: 0 72px;
  height: $bar-height;
  width: 100%;

  @include layout.center-flex;
  flex-direction: row;

  box-shadow: shadows.$nav;
  position: sticky;
  top: 0;
  z-index: 2;

  .link {
    @include layout.center-flex;
    height: 100%;

    .img {
      // height: calc(100% - 34px);
      cursor: pointer;
      object-fit: contain;
    }
  }

  .loginLink {
    white-space: nowrap;
  }

  .menuIcon {
    @include media.up('tablet') {
      display: none;
    }
    @include media.down('mobile') {
      padding: 0;
      margin: 0 16px 0 2%;
    }
  }

  @include media.down('mobile') {
    padding: 0 32px 0 16px;
  }

  .right {
    @include layout.center-flex;
    flex-direction: row;
    cursor: pointer;

    // Split off a separate flex container but retain full height elements
    height: 100%;
  }

  .mobileMenu {
    display: flex;
  }
}

.menuLinks {
  display: flex;
  align-items: center;

  @include media.up('desktop') {
    a,
    button {
      padding-top: 8px;
    }
  }

  @include media.down('tablet') {
    width: 100%;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: $bar-height;
    background-color: colors.$white;
    border-top: 1px solid colors.$light-gray;
  }
}

.mobileLogin {
  @include layout.center-flex;
  justify-content: center;
  height: $bar-height;

  @include media.up('mobile') {
    display: none;
  }
}

.login {
  @include layout.center-flex;
  height: 100%;

  @include media.down('mobile') {
    display: none;
  }
}
