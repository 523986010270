@use 'media';

.affordableIcon {
  position: relative;
  top: 4px;
  margin-right: 8px;

  @include media.up('tablet') {
    display: none;
  }
}

.stockImage {
  width: 560px;
  height: 315px;
  object-fit: cover;

  @include media.down('mobile') {
    width: 100%;
    height: auto;
  }
}
