@use 'media';

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;

  @include media.down('tablet') {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
