@use 'shadows';
@use 'media';

.mobileonly {
  @include media.up('mobile') {
    display: none;
  }
}

.root {
  // To make absolutely positioned elements use this as the root
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: shadows.$paper;

  @include media.down('tablet') {
    flex-direction: column;
  }
}

.title {
  text-align: center;
}

.carousel {
  height: auto;
  padding: 48px;

  @include media.down('mobile') {
    padding: 8px;
  }

  > p {
    padding: 0 32px;
  }
}

.iconReverse {
  float: right;
  transform: rotateY(180deg);
}
