@use 'layout';
@use 'media';

.root {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex-shrink: 0;

  @include media.down('tablet') {
    width: 100%;
  }
}
