@use 'gradients';
@use 'colors';
@use 'layout';
@use 'media';

.root {
  background: gradients.$green-to-blue-linear;
  padding: 5px;

  // Add a little separation between the gradient background
  // and the white content box
  .inner {
    background-color: colors.$white;
    padding: 52px 72px;

    @include layout.center-flex;
    flex-direction: row;

    @include media.down('mobile') {
      flex-direction: column;
      justify-content: center;
    }

    .content {
      margin-right: 64px;

      @include media.down('mobile') {
        margin-right: 0;
        margin-bottom: 32px;
      }
    }

    .button {
      &.noshrink {
        flex-shrink: 0;
      }
    }
  }
}
