@use 'media';

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  &.center {
    align-items: center;
  }

  @include media.down('tablet') {
    flex-direction: column;
  }

  > :first-child {
    margin-right: 96px;

    @include media.down('tablet') {
      margin-right: 0;
      margin-bottom: 32px;
    }
  }

  > :last-child {
    max-width: 100%;

    @include media.down('tablet') {
      align-self: center;
    }
  }

  > iframe {
    max-width: 100%;
    align-self: center;

    @include media.up('tablet') {
      flex-shrink: 0;
    }
  }
}
