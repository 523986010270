@use 'colors';

.root {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 8px 0;
  color: colors.$charcoal;

  .icon {
    position: relative;
    top: 1px;
    margin-right: 24px;
    flex-shrink: 0;
  }
}
