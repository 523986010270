@use 'gradients';
@use 'colors';
@use 'layout';
@use 'media';

$right-side-padding: 64px;

.root {
  background: gradients.$swoops, gradients.$green-to-blue;
  color: colors.$white;

  padding: 64px;

  .headingSpacer {
    height: 64px;

    @include media.down('mobile') {
      height: 16px;
    }
  }

  @include media.down('desktop') {
    padding: 32px;
  }

  @include media.down('mobile') {
    padding: 16px;
  }

  .information {
    @include layout.center-flex;
    flex-direction: row;
    align-items: flex-start;

    @include media.up('desktop') {
      margin-right: -$right-side-padding - 16px;
    }

    @include media.down('tablet') {
      align-items: center;
      flex-direction: column;
    }

    .details {
      display: block;

      @include media.down('tablet') {
        display: none;
      }
    }
  }
}
