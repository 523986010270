@use 'media';

.root {
  display: block;
  margin: 0 auto;
  max-width: 1212px;
  position: relative;

  // TODO: ask Ayush what he actually wants for padding
  padding: 0 32px;

  @include media.down('tablet') {
    max-width: 100%;
    padding: 0 32px;
  }

  @include media.down('mobile') {
    max-width: 100%;
    padding: 0 24px;
  }
}
