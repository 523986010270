@use 'colors';
@use 'media';

.root {
  height: 100%;
  margin: 0 42px 0 23px;

  width: 1px;
  border-right: 1px solid colors.$light-gray;

  @include media.down('mobile') {
    margin-right: 24px;
  }
}
