@use 'colors';

// TODO: ask Ayush *what the actual hell that background is*

$one-swoop-1: radial-gradient(
  ellipse at 120% 200%,
  rgba(7, 52, 92, 0.55) 60%,
  rgba(7, 55, 94, 0.29) 85%,
  rgba(5, 45, 78, 0) 85%
);

$one-swoop: radial-gradient(
  ellipse at 150% 200%,
  rgba(0, 0, 0, 0) 0%,
  rgba(0, 0, 0, 0.12) 62%,
  rgba(0, 0, 0, 0) 62%
);

$swoops: radial-gradient(
    ellipse at 200% -100%,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.12) 62%,
    rgba(0, 0, 0, 0) 62%
  ),
  radial-gradient(
    ellipse at 225% 225%,
    rgba(0, 0, 0, 0) 0%,
    rgba(9, 82, 145, 0.55) 60%,
    rgba(8, 79, 137, 0.29) 85%,
    rgba(8, 79, 137, 0) 85%
  ),
  radial-gradient(
    circle at top 210% right 100%,
    rgba(0, 0, 0, 0) 0%,
    rgba(9, 82, 145, 0.35) 50%,
    rgba(8, 79, 137, 0.36) 62%,
    rgba(8, 79, 137, 0) 62%
  );

$white-swoops: radial-gradient(
    circle at top -120% right 0%,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.08) 62%,
    rgba(0, 0, 0, 0) 62%
  ),
  radial-gradient(
    circle at top 350% right 300%,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 62%,
    rgba(0, 0, 0, 0.08) 80%,
    rgba(0, 0, 0, 0) 80%
  ),
  radial-gradient(
    circle at top -25% left 0,
    rgba(0, 0, 0, 0) 52%,
    rgba(255, 255, 255, 0.12) 82%,
    rgba(255, 255, 255, 0) 82%
  );

$blue-to-green: $white-swoops, linear-gradient(10deg, #5bac4d 1%, #1062b3 97%);
$green-to-blue: $swoops, linear-gradient(10deg, #5ece9e 1%, #287dd1 97%);
$green-to-blue-one-swoop: $one-swoop, linear-gradient(60deg, #35666b 1%, colors.$service-blue 97%);
$blue-green-one-swoop: $one-swoop, linear-gradient(60deg, #3796a3 1%, #45aa99 97%);
$dark-blue: $swoops, linear-gradient(10deg, #13a566 1%, #114d75 97%);
$green-to-blue-linear: linear-gradient(to right, #89ba3b 0%, #1b8dce 100%);

$blue-to-green-blocks: linear-gradient(
  to right,
  rgb(32, 120, 190) 0%,
  rgb(32, 120, 190) 70%,
  rgb(1, 94, 160) 70%,
  rgb(1, 94, 160) 85%,
  rgb(164, 206, 79) 85%
);
