@use 'colors';
@use 'gradients';
@use 'layout';
@use 'media';

$cta-width: 464px;

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  max-width: 100%;
  height: 100vh;

  color: colors.$white;

  @include media.down('tablet') {
    height: auto;
  }

  @include media.down('mobile') {
    flex-direction: column;
    flex-direction: column-reverse;
  }

  .left {
    flex-shrink: 0;
    background: gradients.$blue-to-green;
    padding: 128px 64px;

    // Align the contents inside to the center
    @include layout.center-flex;

    @include media.up('tablet') {
      // Don't let the image take up the majority of the space
      min-width: $cta-width + 128px;
    }

    @include media.down('tablet') {
      width: 50%;
      height: 450px;
      padding: 22px;
    }

    @include media.down('mobile') {
      width: 100%;
      height: 400px;
      padding: 24px;
    }

    > div {
      @include media.up('tablet') {
        max-width: $cta-width;
      }
    }
  }

  .right {
    width: 100%;
    background-size: cover;
    background-position: right center;
    background-repeat: no-repeat;

    @include media.down('tablet') {
      width: 50%;
      height: 450px;
      background-position: right -80px bottom;
    }

    @include media.down('mobile') {
      width: 100%;
      height: 358px;
      background-size: cover;
      background-position: right -30px bottom;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 80px;

    .buttonSpacer {
      width: 24px;

      @include media.down('mobile') {
        width: 0;
        height: 24px;
      }
    }

    @include media.down('tablet') {
      margin: 20px 0;
    }

    @include media.down('mobile') {
      flex-direction: column;
    }

    @include media.up('tablet') {
      margin-left: 0;

      > button {
        width: 80%;
      }
    }
  }
}
