@use 'colors';
@use 'media';

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 200px;
  margin: 30px auto;
  cursor: pointer;

  @include media.up('tablet') {
    width: 150px;
  }

  .item {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: colors.$charcoal;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    &.selected {
      &.dark {
        background-color: colors.$blue;
      }

      &.light {
        background-color: colors.$light-gray;
      }   
    }
  }
}
