@use 'gradients';

.root {
  display: block;
  margin: 60px auto;
  height: 12px;
  width: 100%;

  background: gradients.$blue-to-green-blocks;

  &.secondary {
    height: 4px;
    background: linear-gradient(270deg, #9bcc4d 36%, #068fdc 62%);
  }
}
