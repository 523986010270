@use 'colors';
@use 'shadows';
@use 'gradients';
@use 'media';

.root {
  background-color: colors.$white;
  box-shadow: shadows.$paper;
  border-radius: 4px;
  margin-left: 64px;
  flex-grow: 1;
  cursor: default;

  display: flex;
  flex-direction: column;

  @include media.down('tablet') {
    margin-left: 16px;
    padding: 16px 24px;
    box-shadow: none;
  }

  @include media.down('mobile') {
    margin: 0 0 16px 0;
  }

  .card {
    height: 450px;
    position: relative;
    overflow: hidden;

    @include media.down('mobile') {
      width: 100%;
      height: auto;
    }
  }

  .image {
    height: 100%;

    @include media.down('mobile') {
      width: 100%;
      height: auto;
    }
  }

  .list {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 70%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: linear-gradient(180deg, transparent 0, colors.$white 60%);

    @include media.down('mobile') {
      position: relative;
      height: auto;
      padding: 0;
    }
  }
}
