@use 'colors';
@use 'media';

.root {
  margin: 0 13px;
  color: colors.$charcoal;
  background: none;
  border: none;
  text-align: center;

  @include media.up('large-desktop') {
    white-space: nowrap;
  }

  @include media.up('desktop') {
    width: min-content;
    min-width: 100px;
  }

  @include media.down('tablet') {
    display: none;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.mobile {
  color: colors.$charcoal;
  background: none;
  border: none;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid colors.$light-gray;

  &:hover {
    text-decoration: underline;
  }
}

.blueLink {
  color: colors.$blue;
  @include media.down('desktop') {
    font-size: 10px;
  }
}
