@use 'typography';

// Instantiate the typography mixins here
.h1 {
  @include typography.h1;
}

.h2 {
  @include typography.h2;
}

.h3 {
  @include typography.h3;
}

.h4 {
  @include typography.h4;
}

.subheader1 {
  @include typography.subheader1;
}

.subheader2 {
  @include typography.subheader2;
}

.quote {
  @include typography.quote;
}

.calltoaction1 {
  @include typography.calltoaction1;
}

.calltoaction2 {
  @include typography.calltoaction2;
}

.body1 {
  @include typography.body1;
}

.body2 {
  @include typography.body2;
}

.body3 {
  @include typography.body3;
}

// Utilities to force various font weights without expanding the typography file
.bold {
  font-weight: bold;
}

// Same, but inverse
.regular {
  font-weight: normal;
}

// Allow the container to specify the color
.unset {
  color: inherit;
}

.upcase {
  text-transform: uppercase;
}

.centered {
  text-align: center;
}

.italic {
  font-style: italic;
}
