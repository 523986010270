@use 'colors';

.root {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  letter-spacing: normal;

  .logo {
    height: 32px;
  }

  .plainLink {
    color: colors.$charcoal;
    letter-spacing: normal;
  }

  .blueLink {
    padding-top: 8px;
    color: colors.$blue;
    letter-spacing: normal;
  }

  > * {
    margin-right: 40px;
    letter-spacing: normal;
  }

  .icon {
    position: relative;
    top: 2px;
  }
}
